.benefits {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto 6rem;
    .benefits__item {
        background-color: $lightest-blue;
        padding: 2rem;
        margin-bottom: 1.5rem;
    }

    .benefits__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .benefits__row--headline {
        cursor: pointer;
    }

    .benefits__row--copy {
        overflow: hidden;
        opacity: 0;
        height: 0;
        @extend .trans;

        ul {
            padding-top: 1rem;
            @include max768px {
                padding-top: 2rem;
            }
        }

        &.visible {
            opacity: 1;
            height: auto;
        }
    }

    .benefits__group {
        display: flex;
        align-items: center;
    }

    .benefits__icon {
        width: 70px;
        margin-right: 3rem;
        @include max768px {
            margin-right: 1.5rem;
        }
    }
    
    .benefits__copy {
        h3 {
            text-align: left;
        }
    }

    .benefits__headline {
        font-size: 1.4rem;
        margin-bottom: 0;
        @include max940px {
            font-size: 1.2rem;
            padding-right: 1.5rem;
            text-align: left;
        }
    }

    .benefits__trigger {
        position: relative;
        display: block;
        width: 40px;
        height: 40px;
        font-size: 50px;
        font-weight: 300;
        &:before, 
        &:after {
            content: '';
            position: absolute;
            display: block;
            background-color: $dark-blue;
            height: 4px;
            width: 40px;
            top: calc(50% - 2px);
            left: 0;
            @extend .trans;
        }

        &:after {
            transform: rotate(90deg);
        }

        &.active {
            &:after {
                transform: rotate(0);
                opacity: 0;
            }
        }
    }

    .list {
        padding: 0;
        margin-left: calc(70px + 3rem);
        margin-bottom: 0;
        @include max768px {
            margin-left: 0;
        }
    }
}

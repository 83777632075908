.video {
    position: relative;
    display: block;
    max-width: 700px;
    width: 100%;
    margin: 0 auto 3rem;
    .video__overlay {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 0; left: 0; right: 0; bottom: 0;
        background-color: rgba(9, 24, 55, 0.75);
    }

    .video__src {
        display: block;
        width: 100%;
        height: 400px;
    }

    h4 {
        font-size: 1.75rem;
        margin-bottom: 2rem;
        z-index: 100;
        color: $light-blue;
    }

    .video__button {
        cursor: pointer;
        circle {
            @extend .trans;
        }

        &:hover {
            circle {
                fill: darken(#0A8EEB, 10%);
            } 
        }
    }
}
@import '../config';
@import '../core/breakpoints';

.button {
	display: inline-flex;
	text-align: center;
	text-decoration: none;
	font-family: $font-poppins;
	font-size: 1.1em;
	line-height: 1.25;
	font-weight: 500;
	border-radius: $button-radius;
	cursor: pointer;
	padding: 1rem 2rem;
	border: 0;
	@extend .trans;
	@include max940px {
		display: block;
	}
}

.button--small {
	padding: 0.75rem 1.5rem;
	font-size: 1rem;
}

.button--light-blue {
	background-color: $light-blue;
	color: $white;
	&:hover {
		background-color: darken($light-blue, 10%);
	}
}

.button--dark-blue {
	background-color: $dark-blue;
	color: $white;
	&:hover {
		background-color: lighten($dark-blue, 10%);
	}
}

.button--disabled {
    cursor: wait;
    opacity: .66;
}

.button--external-link {
	&:after {
		content: '';
		display: flex;
		background-image: url("#{$images-path}/ui/icons/icon-external.svg");
		background-repeat: no-repeat;
		background-size: contain;
		width: 18px;
		height: 15px;
		margin-left: 1rem;
	}
}

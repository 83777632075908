.quote {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include max1024px {
        flex-direction: column;
    }

    .quote__col--copy {
        padding: 3rem;
        @include max1024px {
            padding: 0;
        }
        
        p {
            text-align: left;
            margin-bottom: 1rem;
            @include max1024px {
                text-align: center;
            }
        }
    }

    .quote__image {
        @include max1024px {
            display: block;
            width: 100%;
        }
    }
}
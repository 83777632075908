@import '../config';

.hero {
	position: relative;
	margin-top: -130px;
	padding: 0;
	@include max480px {
		margin-top: -90px;
	}

	.hero__image {
		display: block;
		max-width: 1920px;
		width: 100%;
		margin: 0 auto;
		img {
			display: block;
			width: 100%;
		}
	}

	.hero__inner {
		position: absolute;
		display: flex;
		justify-content: center;
		flex-direction: column;
		top: 57.5%; left: 50%;
		transform: translate(-50%, -50%);
		max-width: $grid-max-width;
		width: 100%;
		padding: 0 1rem;
		box-sizing: border-box;
	}

	h1 {
		text-align: left;
		color: $white;
	}

	.hero__copy {
		text-align: left;
		margin-bottom: 3rem;
		max-width: 800px;
		width: 100%;
		font-size: 1.2rem;
		line-height: 1.75;
		color: $white;
		@include max640px {
			font-size: 1.1rem;
		}
	}
}

.hero--subpage {
	.hero__inner {
		@include max640px {
			top: 65%;
		}
	}

	h1 {
		text-align: center;
	}
}

.heart {
    display: inline-block;
    position: absolute;
    animation: pulse 1s infinite;
    height: 27px;
    bottom: -10px;
    margin: 0 7.5px;
  }
  
  .heart {
    position: relative;
    width: 30px; 
    height: 27px; 
  }
  
  .heart:before,
  .heart:after {
    position: absolute;
    content: "";
    left: 15px; 
    top: 0;
    width: 15px; 
    height: 24px;
    background: $white;
    border-radius: 15px 15px 0 0;
    transform: rotate(-45deg);
    transform-origin: 0 100%;
    animation: pulsecolor 1s infinite;
  }
  
  .heart:after {
    left: 0;
    transform: rotate(45deg);
    transform-origin: 100% 100%;
    animation: pulsecolor 1s infinite;
  }
  
  @keyframes pulse {
    10% {
      transform: scale(1.1);
    }
  }
  
  @keyframes pulsecolor {
    10% {
      background: $white;
    }
  }

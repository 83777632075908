.socials {
    display: flex;
    list-style: none;
    gap: 1rem;
    margin-bottom: 0;
    height: 30px;
    .socials__link {
        .svg {
            
        }
    }
}

@import '../config';
@import '../core/breakpoints';

.navigation {
	display: flex;
	align-items: center;

	.navigation__list {
		display: flex;
		justify-content: space-between;
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.navigation__item {
		margin: 0 1rem;
		padding: 1.5rem 0;
	}

	.navigation__item--has-submenu {
		position: relative;

		&:after {
			content: '';
			width: 18px;
			height: 12px;
			position: absolute;
			background-image: url("#{$images-path}/ui/submenu-triangle.svg");
			background-repeat: no-repeat;
			left: 50%;
			transform: translateX(-50%) rotate(180deg);
			top: 46px;
		}

		&:hover {
			&:after {
				display: block;
				transform: translateX(-50%) rotate(0);

				@include max940px {
					display: flex;
				}
			}

			.submenu {
				display: block;

				@include max940px {
					display: flex;
				}

				@include max640px {
					padding-top: 1rem;
				}
			}
		}
	}

	.navigation__item--active {
		.navigation__link {
			font-weight: bold;
			color: $light-blue;
			text-decoration: none;

			&:hover {
				text-decoration: none;
			}
		}
	}

	.navigation__link {
		color: $white;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

.navigation--mobile {
	display: flex;
	align-items: center;
	height: 100%;

	.navigation {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		z-index: 200;
		padding-left: 0;
		margin-bottom: 0;
	}

	.navigation__list {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.navigation__item {
		text-align: center;
		padding-right: 0;
		margin-bottom: 2rem;
		border-bottom: 0;
		border-top: 0;

		@include max940px {
			padding: 0;
		}

		.navigation__link {
			display: block;
			width: 100%;
			font-size: 1.25rem;
			color: $hamburger-overlay-item-color;

			@include max640px {
				font-size: 1.1rem;
			}
		}
	}
}
#modal-gdpr {
    display: none;
}

.modal {
    text-align: left;
    z-index: 10000;
    h2 {
        font-size: 2rem;
        text-align: left;
        margin-bottom: 1rem;
    }

    h3 {
        font-size: 1.25rem;
        text-align: left;
        margin-bottom: 0.5rem;
    }

    p, ul {
        text-align: left;
        margin-bottom: 1rem;
        font-size: 0.9rem;
        line-height: 1.5;
    }

    strong {
        font-size: 0.9rem;
    }
}
@import '../config';
@import '../core/helpers';
@import '../core/breakpoints';

.jobOffer--top {
    .jobOffer__list {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 1000px;
        width: 100%;
        margin: 0 auto 110px;
        gap: 1.5rem;
        @include max940px {
            flex-flow: row wrap;
        }

        @include max640px {
            margin: 0 auto 3rem;
        }
    }

    .tile {
        width: 300px;
    }

    .tile__department {
        min-height: 71px;
    }

    .tile__icon {
        svg {
            //max-width: 65px;
            width: 100%;
            max-height: 65px;
        }
        
        path {
            fill: $white;
        }
    }

    .tile__position {
        text-align: center;
        min-height: 45px;
    }
}

.jobOffer--categories {
    .tile__icon {
        path {
            fill: $dark-blue;
        }
    }

    .tile__department {
        min-height: 106px;
    }
}

.jobOffer--full {
    display: flex;
    justify-content: space-between;
    background-color: $lightest-blue;
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 2rem 3rem;
    box-sizing: border-box;
    @include max940px {
        flex-direction: column;
    }

    @include max640px {
        padding: 1.5rem;
    }

    h3 {
        text-align: left;
        margin-bottom: 2rem;
        font-size: 1.5rem;
        @include max480px {
            font-size: 1.25rem;
            margin-bottom: 1rem;
        }
    }

    .jobOffer__category {
        width: 260px;
        padding-right: 2rem;
        box-sizing: border-box;
        @include max940px {
            width: 100%;
            padding-right: 0;
            margin-bottom: 1rem;
        }
    }

    .jobOffer__departments {
        list-style: none;
        padding-left: 0;
        @include max940px {
            display: flex;
            flex-flow: row wrap;
            gap: 1rem 1.5rem;
        }
    }

    .jobOffer__departmentsItem {
        margin-bottom: 1.5rem;
        @include max940px {
            margin-bottom: 0;    
        }

        @include max640px {
            font-size: 0.85rem;
        }
    }

    .form__checkbox {
        @include max940px {
            margin-bottom: 0;
        }
    }

    .jobOffer__list {
        width: calc(100% - 260px);
        @include max940px {
            width: 100%;
        }
    }

    .jobOffer__filter {
        display: flex;
        gap: 1.5rem;
        margin-bottom: 2rem;
        @include max640px {
            flex-direction: column;
        }

        @include max480px {
            gap: 1rem;
        }

        .jobOffer__row {
            align-items: center;
            height: 36px;
            @include max640px {
                height: auto;
            }
        }

        .form__checkbox {
            margin-bottom: 0;
            @include max640px {
                font-size: 0.9rem;
            }
        }

        .jobOffer__selection-process-only {
            h3 {
                @include max640px {
                    display: none;
                }
            }
        }
    }

    .jobOffer__row {
        display: flex;
        gap: 2rem;
    }
}

.jobOffer-list {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    @include max480px {
        gap: 1.5rem;
    }

    .jobOffer-list__item {
        position: relative;
        display: flex;
        align-items: flex-start;
        gap: 1.5rem;
        border: 1px solid #AEAEB2;
        padding: 1.5rem;
        background-color: $white;
        @include max480px {
            gap: 0.75rem;
            padding: 1rem;
        }
    }

    .jobOffer-list__row {
        width: 100%;
    }

    .jobOffer-list__name {
        text-align: left;
        font-size: 1.5rem;
        margin-bottom: 1rem;
        @include max480px {
            margin-bottom: 0.75rem;
            font-size: 1.25rem;    
        }
    }

    .jobOffer-list__details,
    .jobOffer-list__info {
        display: flex;
    }

    .jobOffer-list__details {
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .jobOffer-list__info {
        gap: 2rem;
    }

    .jobOffer-list__position {
        max-width: 400px;
    }

    .jobOffer-list__position,
    .jobOffer-list__city {
        font-size: 1rem;
        @include max480px {
            font-size: 0.85rem;
        }
    }

    .jobOffer-list__link {
        font-weight: bold;
        @include max940px {
            font-size: 0.9rem;
        }
    }

    .jobOffer-list__image {
        fill: $dark-blue;
        width: 50px;
        @include max640px {
            display: none;
        }

        svg {
            fill: #091837;
        }
    }
}

.jobOffer-detail {
    padding: 4rem;
    background-color: $lightest-blue;
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    @include max940px {
        padding: 2rem;
    }

    h2, h3, h4, p, li {
        text-align: left;
    }

    h3 {
        font-size: 1.75rem;
        margin-bottom: 1.5rem;
    }

    ul {
        margin-bottom: 2rem;
        li {
            background-image: url("#{$images-path}/ui/indent.svg");
            background-repeat: no-repeat;
            background-position: 0 6px;
            list-style: none;
            padding-left: 1.5rem;
            margin-bottom: 0.75rem;
        }
    }

    p {
        margin-bottom: 2rem;
    }

    .jobOffer-detail__labels {
        display: flex;
        gap: 2rem;
        margin-bottom: 2rem;
        @include max940px {
            flex-flow: row wrap;
            gap: 1rem;
        }

        @include max640px {
            gap: 0.5rem;
        }

        .label {
            @include max640px {
                font-size: 0.9rem;
            }
        }
    }
}
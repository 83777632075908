.attachement-uploader {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2rem;
    flex-flow: row wrap;

    .attachement-uploader__dropzone {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 1px dashed $gray;
        padding: 1rem;
        width: 200px;
        height: 200px;
        box-sizing: border-box;
        cursor: pointer;
    }

    .attachement-uploader__icon {
        display: block;
        margin-bottom: 1rem;   
        width: 40px;
    }
    
    .attachement-uploader__note {
        margin-bottom: 0;
        font-size: 0.8rem;
        line-height: 1.4;
        color: $gray;
    }

    .attachement-uploader__files {
        display: flex;
        gap: 1rem;
        flex-flow: row wrap;
    }

    .attachement-uploader__fileItem {
        display: flex;
        flex-direction: column;
        width: 125px;
        margin-right: 2rem;
        /*
        button {
            padding-top: 0.5rem;
            border: 0;
            background-color: transparent;
        }
        */
    }

    .attachement-uploader__fileIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $gray;
        padding: 0.5rem;
        height: 125px;
    }

    .attachement-uploader__fileThumb {

    }
    
    .attachement-uploader__fileThumb--pdf {
        max-width: 75px;
        width: 100%;
    }
    
    .attachement-uploader__fileIcon {
       
    }

    .attachement-uploader__fileThumb {

    }

    .attachement-uploader__fileName {
        font-size: 0.8rem;
        line-height: 1.4;
        color: darken($gray, 5%);
        height: 50px;
        padding-top: 0.5rem;
        word-wrap: break-word;
        overflow: hidden;
    }
}

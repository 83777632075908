.contact {
    .contact__row {
        justify-content: space-between;
        gap: 2rem;
        @include max640px {
            flex-flow: row wrap;
        }
    }

    .contact__col {
        width: calc(50% - 1rem);
        @include max640px {
            width: 100%;
        }
    }

    .contact__map {
        width: 100%;
    } 

    .contact__address {
        display: block;
        font-style: normal;
        margin-bottom: 1.5rem;
        @include max768px {
            br {
                display: block;
            }
        }
    }

    h3 {
        font-size: 1.75rem;
        text-align: left;
        margin-bottom: 2rem;
        @include max640px {
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }
    }

    .contact__map {
        height: 300px;
    }

    .contact__info {
        display: block;
        margin-bottom: 2rem;
        @include max768px {
            br {
                display: block;
            }
        }
    }
}
@import '../config';

.header {
	position: relative;
	background-color: transparent;
	padding: 2rem 0;
	z-index: 1000;
	@include max940px {
		z-index: 3000;
	}

	@include max480px {
		padding: 1rem 0;
	}

	.wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.header__logo {
	.header__logo-link {
		max-width: 200px;
		width: 100%;
		@include max640px {
			max-width: 175px;
		}

		@include max480px {
			max-width: 150px;
		}
	}
}

.header__navigation {
	.navigation {
		@media screen and (max-width: $hamburger-breakpoint) {
			display: none;
		}
	}
}

.galleryIn {
    margin: 3rem 0;
}

.gallery {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    max-width: 1000px;
    width: 100%;
    margin: 4rem auto;
    gap: 20px;
    
    h3 {
        text-align: left;
    }

    .gallery__item {
        box-shadow: 2px 4px 25px 0px rgba(93, 92, 92, 0.15);
        position: relative;
        width: 300px;
        //height: 200px;
        overflow: hidden;
        text-decoration: none;
    }

    .gallery__image {
        display: block;
        width: 100%;
    }

    .gallery__label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
        background-color: $white;
        min-height: 50px;
        line-height: 1.5;
    }

    .gallery__overlay {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 0; left: 0; bottom: 0; right: 0;
        background-repeat: no-repeat;
        background-position: center center;
        opacity: 0;
        @extend .trans;
        &:hover {
            background-color: rgba(9, 24, 55, .50);
            background-image: url("#{$images-path}/ui/zoom-button.svg");
            opacity: 1;
        }
    }
}

.inTextGallery {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    gap: 20px;
    margin-bottom: 4rem;
    .inTextGallery__item {
        width: 500px;
    }

    .inTextGallery__label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
        background-color: $white;
        min-height: 50px;
        box-sizing: border-box;
    }

    .inTextGallery__image {
        display: block;
        width: 100%;
    }
}

@import '../config';
@import '../core/helpers';
@import '../core/breakpoints';

.label {
    padding: 1rem 2rem 1rem 3.75rem;
    background-color: $light-blue;
    color: $white;
    border-radius: 5px;
    font-weight: 500;
    line-height: 1;
    background-repeat: no-repeat;
    background-size: 25px auto;
    background-position: 1rem 50%;
    &.label--department {
        background-image: url("#{$images-path}/ui/icons/icon-department.svg");
    }

    &.label--hospital {
        background-image: url("#{$images-path}/ui/icons/icon-hospital.svg");
    }

    &.label--date {
        background-image: url("#{$images-path}/ui/icons/icon-date.svg");
    }

    &.label--date-2 {
        background-image: url("#{$images-path}/ui/icons/icon-date-2.svg");
    }

    &.label--time {
        background-image: url("#{$images-path}/ui/icons/icon-time.svg");
    }

    &.label--capacity {
        background-image: url("#{$images-path}/ui/icons/icon-capacity.svg");
    }

    &.label--capacity-full {
        opacity: 0.5;
    }
}
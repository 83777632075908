.tiles {}

.tiles--department {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-flow: row wrap;
    
    .tile {
        svg path {
            fill: $dark-blue;
        }

        .tile__department {
            min-height: 77px;
            @include max940px {
                min-height: 50px;
            }
        }
    }
}

.tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    width: 250px;
    text-decoration: none;
    box-sizing: border-box;
    @extend .trans;

    &:hover {
        .tile__link {
            text-decoration: none;
        }
    }

    .tile__icon {
        margin-bottom: 1.5rem;
        @extend .trans;
        
        svg {
            fill: $white;
            min-height: 65px;
        }
    }

    .tile__department {
        margin-bottom: 3rem;
        font-size: 1.75rem;
        @extend .trans;
        @include max640px {
            font-size: 1.5rem;    
            margin-bottom: 2rem;
        }
    }

    .tile__city {
        margin-bottom: 1.5rem;
        @include max640px {
            margin-bottom: 1rem;
        }
    }

    .tile__link {
        font-weight: bold;
        color: $dark-blue;
        text-decoration: underline;
    }

    &:hover {
        .tile__department {
            //color: $light-blue;
        }
    }
}

.tile--dark {
    background-color: $dark-blue;
    color: $white;
    .tile__link {
        color: $white;
    }
}

.tile--light {
    background-color: $lightest-blue;
    color: $dark-blue;
}
.events {
    .events__item {
        display: flex;
        justify-content: space-between;
        text-align: left;
        background-color: $lightest-blue;
        margin-bottom: 3rem;
        @include max940px {
            flex-flow: row wrap;
            max-width: 600px;
            width: 100%;
            margin: 0 auto 3rem;
        }
    }

    .events__col--image {
        width: 40%;
        @include max940px {
            width: 100%;
        }

        .events__image {
            display: block;
            width: 100%;
        }    
    }
    
    .events__col--copy {
        width: 60%;
        padding: 2rem 4rem;
        box-sizing: border-box;
        @include max940px {
            width: 100%;
        }

        @include max640px {
            padding: 2rem;
        }
    }

    .events__labels {
        display: flex;
        margin-bottom: 0.5rem;
        gap: 0.5rem;
        flex-flow: row wrap;
        .label {
            padding: 0.75rem 1.5rem 0.75rem 3rem;
            background-size: 20px auto;
            font-size: 0.9rem;
        }
    }

    h3, p {
        text-align: left;
    }

    h3 {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
    }

    p {
        margin-bottom: 1.5rem;
    }

    .events__location {
        font-weight: 600;
        display: block;
        margin-bottom: 0.5rem;
        background-image: url("#{$images-path}/ui/icons/icon-location.svg");
        background-repeat: no-repeat;
        background-position: 0 50%;
        padding: 1rem 1rem 1rem 2rem;
    }

    .events__button {
        display: flex;
        gap: 1rem;
        @include max480px {
            flex-flow: row wrap;
        }
    }
}

.events--detail {
    padding: 4rem;
    background-color: $lightest-blue;
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    @include max940px {
        padding: 2rem;
    }

    @include max640px {
        padding: 0;
        background-color: none;
    }

    h2 {
        text-align: left;
    }

    .button {
        margin-bottom: 4rem;
    }

    .events__poster {
        display: block;
        width: 100%;
        img {
            display: block;
            width: 50%;
        }
    }
}
@import '../config';

.form {
	max-width: 800px;
	width: 100%;
	margin: 0 auto;
	.form__control {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	.form__control--checkboxes {
		flex-direction: column;
	}

	.form__control--button {
		justify-content: center;
	}

	.form__group {
		display: flex;
		flex-direction: column;
		margin-bottom: 2rem;
		a label {
			cursor: pointer;
		}
	}

	.form__group--label {
		margin-bottom: 0.5rem;
	}

	.form__group--checkboxes {
		display: flex;
		flex-direction: initial;
		gap: 1.5rem;
	}

	@for $i from 1 through 10 {
		.form__control--#{$i}c {
			gap: $form-columns-gap;
			.form__group {
				width: calc(100%/#{$i});
			}
		}
	}

	.form__control--3c {
		@include max480px {
			flex-flow: row wrap;
		}

		.form__group {
			@include max480px {
				width: 100%;
			}
		}
	}

	.form__group--1c {
		.form__control {
			width: 100%;
		}
	}

	.form__label {
		font-weight: bold;
		margin-bottom: 5px;
	}

	.form__input,
	.form__select,
	.form__textarea {
		width: 100%;
		box-sizing: border-box;
		padding: $form-input-padding-tops $form-input-padding-sides;
		border: $form-input-border-width solid $form-input-border-color;
		border-radius: $form-input-border-radius;
		font-size: $form-input-text-size;
		line-height: $base-line-height;
		color: $form-input-text-color;
		background-color: $form-input-background-color;
		@extend .trans;
		&:focus {
			border-color: $dark-blue;
			outline: none;
		}
	}

	.form__input--invalid:is(input, select, .PhoneInput) {
		background-color: rgb(254 246 244);
		border: 1px solid red;
		input {
			background-color: rgb(254 246 244);
		}
	}

	div.form__input--invalid {
		.attachement-uploader .attachement-uploader__dropzone {
			border: 1px dashed red;
			p {
				color: red;
			}
		}
	}

	.form__checkbox.form__input--invalid {
		color: red;
		.checkmark {
			border: 1px solid red;
		}

		input:checked ~ .checkmark {
			border: 0;
		}
	}

	.form__error {
		margin-top: 5px;
		font-size: 0.8rem;
		font-weight: 500;
		color: red;
	}

	.form__note {
		display: block;
		font-size: 0.9rem;
		color: #868686;
		margin-bottom: 1rem;
	}

	.form__textarea {
		$minimum-rows: 6;

		min-height: calc((#{$form-input-text-size} * #{$base-line-height}) * #{$minimum-rows} + #{$form-input-padding-tops} * 2);
	}

	.form__button {
		width: 100%;
	}

	.form__sectionHeadline {
		margin-bottom: 1.5rem;
		font-size: 1.5rem;
		text-align: left;
	}
}

.form--extended {
	.form__control {
		margin-bottom: 2rem;
	}
}

// Custom checkbox
/* The container */
.form__checkbox {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	  }

	.checkmark {
		position: absolute;
		top: -3px;
		left: 0;
		height: 25px;
		width: 25px;
		background-color: #eee;
		border-radius: 5px;
		&:after {
			content: "";
			position: absolute;
			display: none;
		}
	}

	&:hover input ~ .checkmark {
		background-color: #DCDCDD;
	}

	input:checked ~ .checkmark {
		background-color: $dark-blue;
	}

	input:checked ~ .checkmark:after {
		display: block;
	}

	.checkmark {
		@extend .trans;
	}

	.checkmark:after {
		left: 9px;
		top: 5px;
		width: 5px;
		height: 10px;
		border: solid white;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}

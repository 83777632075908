.footer {
	padding: 3rem 0;
	h2 {
		font-size: 2rem;
		text-align: left;
	}
	
	h3 {
		font-size: 1rem;
		line-height: 1.75;
		font-weight: 400;
		text-align: left;
	}

	a {
		display: block;
		font-size: 0.9rem;
		text-decoration: none;
		line-height: 1.8;
		&:hover {
			text-decoration: underline;
		}
	}
	
	.footer__row {
		display: flex;
		justify-content: space-between;
		@include max768px {
			flex-direction: column;
		}
	}

	.footer__row--contacts {
		border-bottom: 1px solid #D9D9D9;
		padding-bottom: 2rem;
	}
	
	.footer__row--copyright {
		padding-top: 2rem;
		align-items: center;
	}

	.footer__col {
		@include max768px {
			margin-bottom: 2rem;
		}
	}

	.footer__contact {
		padding: 0.5rem 0 0.5rem 3rem;
		background-position: center left;
		background-repeat: no-repeat;
		background-size: 2rem 2rem;
	}

	.footer__contact--web {
		background-image: url("#{$images-path}/ui/icons/icon-web.svg");
	}

	.footer__contact--phone {
		background-image: url("#{$images-path}/ui/icons/icon-phone.svg");
	}

	.footer__contact--mail {
		background-image: url("#{$images-path}/ui/icons/icon-mail.svg");
	}

	.footer__headline {
		display: block;
		margin-bottom: 0.5rem;
		font-size: 0.9rem;
		color: #616161;
		min-height: 80px;
		@include max768px {
			min-height: auto;
		}

		strong {
			display: block;
			margin-bottom: 0.25rem;
			font-size: 1rem;
			color: $dark-blue;
		}
	}

	.footer__copyright {
		font-size: .9rem;
		color: #616161;
	}
}

.footerNavigation {
	display: flex;
	gap: 1.5rem;
	list-style: none;
	padding: 1rem 0;
	margin-bottom: 0;
	a {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
}

.calendar {
    margin-bottom: 3rem;
    h4 {
        font-size: 1.1rem;
        text-align: left;
    }

    .calendar__nav {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3rem;
        margin-bottom: 1.5rem;
    }

    .calendar__arrow {
        width: 40px;
        svg {
            display: block;
            width: 100%;
        }
    }

    .calendar__arrow--right {
        transform: rotate(180deg);
    }

    .calendar__year {
        font-size: 1.1rem;
    }
}

.calendar__table {
    width: 100%;
    tr {
        width: 100%;
    }

    .calendar__day {
        position: relative;
        width: calc(100%/7);
        height: 100px;
        vertical-align: top;
        border: 1px solid gray;
        margin-right: -1px;
        margin-bottom: -1px;
        box-sizing: border-box;
        padding: 0.5rem;
    }

    .calendar__day--current {
        font-weight: bold;
    }

    .calendar__status--booked {
        position: absolute;
        bottom: 1rem;
        left: 0; right: 0;
        height: 1rem;
        background-color: red;
    }
}
.usp {
    margin-bottom: 6rem;
    .usp__row {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 3rem;
        flex-flow: row wrap;
        gap: 2rem 0;
    }

    .usp__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        width: 33%;
        @include max640px {
            width: 50%;
        }
    }

    .usp__number {
        font-size: 50px;
        font-weight: bold;
        @include max940px {
            font-size: 35px;
        }
    }

    .usp__label {
        font-size: 1rem;
        color: gray;
    }
}
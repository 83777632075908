.advantages {
    h3, p {
        text-align: left;
    }

    h3 {
        font-size: 1.75rem;
        color: $light-blue;
        margin-bottom: 2rem;
    }

    p {
        margin-bottom: 0;
    }

    .advantages__row {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5rem;
        @include max940px {
            flex-direction: column;
        }

        &:nth-child(odd) {
            .advantages__col--image {
                order: -1;
                @include max940px {
                    order: 1;
                }
            }

            .advantages__col--copy {
                padding-left: 3rem;
                @include max940px {
                    padding-left: 0;    
                }
            }
        }
    }

    .advantages__col--copy {
        padding-right: 3rem;
        @include max940px {
            padding-right: 0;
        }

        h3, p {
            @include max940px {
                text-align: center;
            }
        }

        p {
            @include max940px {
                margin-bottom: 1rem;
            }
        }
    }

    .advantages__image {
        @include max940px {
            display: block;
            width: 100%;
        }
    }
}
.recruitment {
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    .recruitment__list {
        list-style-type: none;
        &:first-child {
            border-bottom: 1px solid #D9D9D9;
        }

        &:last-child {
            margin-top: 4rem;
        }
    }

    .recruitment__item {
        display: flex;
        align-items: flex-start;
        gap: 2rem;
        position: relative;
        margin-bottom: 4rem;
        .list {
            margin-top: 0.5rem;
        }
    }

    .recruitment__indent {
        display: flex;
        width: 40px;
        font-size: 5rem;
        line-height: 1;
        font-weight: 500;
        color: #C6CBCE;
    }

    .recruitment__info {
        width: calc(100% - 40px);
    }

    .recruitment__copy {
        font-size: 1rem;
        line-height: 2;
    }

    h3 {
        font-size: 1.5rem;
        text-align: left;
        color: $light-blue;
        margin-bottom: 2rem;
    }
}

.submenu {
    display: none;
    position: absolute;
    top: 55px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(10, 142, 235, 0.75);
    padding: 2rem;
    width: 175px;
    list-style: none;
    z-index: -1000;
    @include max940px {
        position: relative;
        display: flex;
        justify-content: center;
        top: auto;
        left: auto;
        transform: none;
        width: auto;
        gap: 2rem;
        flex-flow: row wrap;
        padding-bottom: 0;
        z-index: 1;
    }

    @media screen and (max-width: 530px) {
        gap: 1rem;
    }

    .submenu__item {
        background-image: url("#{$images-path}/ui/indent-dark.svg");
        @include max940px {
            white-space: nowrap;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
    
    .submenu__link {
        color: $white;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}